<template>
  <a-form
    ref="loginForm"
    :model="loginForm"
    :rules="loginRules"
    class="columns is-multiline"
    @submit="handleSubmit"
  >
    <a-form-item class="column is-12" :label="$t('Email')" name="email">
      <a-input
        v-model:value.trim="loginForm.email"
        :size="formSize"
        autocomplete="email"
        type="email"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Password')" name="password">
      <a-input-password
        v-model:value.trim="loginForm.password"
        :size="formSize"
      />
    </a-form-item>
    <div class="column is-12 recaptcha-column">
      <Recaptcha ref="recaptcha" @verify="handleRecaptchaVerify" @error="handleRecaptchaError" />
    </div>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="formSize"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        html-type="submit"
        @click="handleSubmit"
      >
        {{ $t('Log In') }}
      </a-button>
      <RouterLink
        v-if="showForgotLink"
        :to="{ name: 'forgot-password' }"
        class="has-text-secondary"
      >
        {{ $t('Forgot password') }}
      </RouterLink>
    </div>
  </a-form>
</template>

<i18n>
{
"en":{
"Email":"Email",
"Password":"Password",
"Log In":"Log In",
"Forgot password":"Forgot password?",
"invalid-email":"Invalid email format.",
"email-required":"Email is required.",
"password-min":"Password must be 6 characters or more.",
"password-required":"Password is required."
},
"ja":{
"Email":"メールアドレス",
"Password":"パスワード",
"Log In":"ログイン",
"Forgot password":"パスワードを忘れた場合",
"invalid-email":"無効なメールアドレス形式",
"email-required":"メールアドレスは必須です",
"password-min":"パスワードは6桁以上である必要があります",
"password-required":"パスワードは必須です"
}
}
</i18n>

<script>
import Message from 'ant-design-vue/lib/message';
import Recaptcha from '@/components/Recaptcha';

export default {
  name: 'LoginForm',
  components: {
    Recaptcha,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    showForgotLink: {
      type: Boolean,
    },
  },
  emits: ['submit'],
  data() {
    return {
      formSize: 'large',
      loginForm: {
        email: undefined,
        password: undefined,
      },
      loginRules: {
        email: [
          {
            type: 'email',
            message: this.$t('invalid-email'),
            trigger: ['change', 'blur', 'input'],
          },
          {
            required: true,
            message: this.$t('email-required'),
            trigger: ['change', 'blur'],
          },
        ],
        password: [
          {
            min: 6,
            message: this.$t('password-min'),
            trigger: ['change', 'blur'],
          },
          {
            required: true,
            message: this.$t('password-required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$refs.loginForm.validate().then(() => {
        this.$refs.recaptcha.execute();
      });
    },
    handleRecaptchaVerify(token) {
      this.$emit('submit', { token, data: this.loginForm });
    },
    handleRecaptchaError(message) {
      Message.error(message, 5);
      this.$refs.recaptcha.reset();
    },
  },
};
</script>
