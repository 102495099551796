<template>
  <VueRecaptcha
    ref="invisibleRecaptcha"
    :sitekey="recaptchaKey"
    :load-recaptcha-script="true"
    recaptcha-host="www.recaptcha.net"
    size="invisible"
    badge="hidden"
    @verify="onVerify"
  />
</template>

<i18n>
{
  "en": {
    "error": "Invalid recaptcha token",
    "expired": "Recaptcha token expired"
  },
  "ja": {
    "error": "無効なrecaptchaトークン",
    "expired": "Recaptchaトークンの有効期限が切れました"
  }
}
</i18n>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'Recaptcha',
  components: {
    VueRecaptcha,
  },
  emits: ['error', 'verify'],
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
      retry: 0,
      maxRetry: 9,
      timeoutHandler: undefined,
    };
  },
  beforeUnmount() {
    this.clearTimeout();
  },
  methods: {
    onVerify(token) {
      if (!token) {
        console.log('no token');
        this.$emit('error', this.$t('error'));
      } else {
        this.$emit('verify', token);
      }
    },
    execute() {
      if (typeof this.$refs.invisibleRecaptcha.widgetId !== 'undefined') {
        console.log('execute');
        this.$refs.invisibleRecaptcha.execute();
      } else if (this.retry < this.maxRetry) {
        console.log('retry');
        this.retry += 1;
        this.clearTimeout();
        this.timeoutHandler = setTimeout(() => this.execute(), 1000);
      }
    },
    reset() {
      this.$refs.invisibleRecaptcha.reset();
    },
    clearTimeout() {
      if (this.timeoutHandler) clearTimeout(this.timeoutHandler);
    },
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-column {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
