<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container is-max">
          <h2 v-if="!fromResetPassword" class="has-text-centered is-3 title">
            {{ $t('title') }}
          </h2>
          <h2 v-else class="has-text-centered is-3 title">
            {{ $t('Password reset successful') }}
          </h2>
          <div v-if="fromResetPassword" class="is-size-7 text-center">
            {{ $t('fromResetPassword') }}
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <LoginForm
          ref="loginForm"
          :show-forgot-link="true"
          :is-submitting="isSubmitting"
          @submit="handleLogin"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"title":"Login to SRS",
"Password reset successful":"Password reset successful",
"fromResetPassword": "Now you can use your new password to login to your account."
},
"ja":{
"title":"RTSRSにログイン",
"Password reset successful":"パスワードリセットが完了しました",
"fromResetPassword": "新しいパスワードでログインが可能になりました"
}
}
</i18n>

<script>
import LoginForm from '@/views/user/components/LoginForm';

export default {
  name: 'Login',
  components: { LoginForm },
  data() {
    return {
      isSubmitting: false,
      redirect: undefined,
      otherQuery: {},
      fromResetPassword: false,
    };
  },
  watch: {
    $route: {
      handler(route) {
        const { query } = route;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
          this.fromResetPassword = query.from ? query.from === 'reset-password' : false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleLogin({ token, data }) {
      this.isSubmitting = true;
      const payload = {
        data,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE',
        },
      };
      this.$store
        .dispatch('user/login', payload)
        .then(() => {
          const { href } = this.$router.resolve({
            path: this.redirect || '/',
            query: this.otherQuery,
          });
          setTimeout(() => {
            window.location.replace(href);
          }, 100);
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
          this.$refs.loginForm.$refs.recaptcha.reset();
        });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>
